import { useRoutes } from "react-router-dom";

// project import
import AreaInternaRoutes from "./AreaInternaRoutes";
import AreaAbertaRoutes from "./AreaAbertaRoutes";
import GeralRoutes from "./GeralRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([AreaAbertaRoutes, AreaInternaRoutes, GeralRoutes]);
}
