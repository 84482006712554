import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// ServiceWorker import
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// project import
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import "./global.scss";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

let persistor = persistStore(store);

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LeUYs8pAAAAANmHBwXQTzesNylRotV7vxBZIwOf"
    scriptProps={{
      async: false,
      defer: false,
      appendTo: "head",
    }}>
    <StrictMode>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename="/">
            <App />
          </BrowserRouter>
        </PersistGate>
      </ReduxProvider>
    </StrictMode>
  </GoogleReCaptchaProvider>,

  document.getElementById("root")
);

// Realiza a chamada para registrar o SW
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
