import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dadosCliente: null,
};

const client = createSlice({
  name: "client",
  initialState,
  reducers: {
    setDadosCliente(state, action) {
      state.dadosCliente = action.payload;
    },
    resetDadosCliente(state) {
      state.dadosCliente = initialState.dadosCliente;
    },
  },
});

export default client.reducer;

export const { setDadosCliente, resetDadosCliente } = client.actions;
