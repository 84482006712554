import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ehProducao, ehHomologacao, get } from "./services/api";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  setDefaultColors,
  setLogos,
  setKeepQuitaLogo,
  resetWhitelabel,
} from "@/store/reducers/user";
import {
  getLightColorButton,
  getPrimaryColorButton,
  getTopBarColor,
} from "./services/utils";
import Routes from "./routes";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/ScrollTop";

const App = () => {
  const dispatch = useDispatch();
  const [carregar, setCarregar] = useState(false);

  useEffect(() => {
    setCarregar(true);
  }, []);

  useEffect(() => {
    const setDefaultMovipay = () => {
      dispatch(
        setDefaultColors({
          topBarColor: getTopBarColor(),
          primary_color_button: getPrimaryColorButton(),
          light_color_button: getLightColorButton(),
        })
      );
      dispatch(
        setLogos({
          light_background: "/logo.png",
          dark_background: "/logo_branco.png",
        })
      );
      dispatch(setKeepQuitaLogo(false));
      document.title = "Movipay";
    };

    const loadPartnerInfo = async (subdomain) => {
      try {
        dispatch(activeSpinner());
        const result = await get(
          `public/info-whitelabel-partner/${subdomain}`,
          {}
        );
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(
            setInfoDialog({
              open: true,
              descricao: result.error,
            })
          );
          setDefaultMovipay();
          return;
        }
        dispatch(setDefaultColors(result.colors));
        dispatch(setLogos(result.logos));
        dispatch(setKeepQuitaLogo(result.keep_quitalogo));
        document.title = result.title_site;
      } catch (e) {
        setDefaultMovipay();
        console.log(e);
        setInfoDialog({
          open: true,
          descricao:
            'Nâo foi possível carregar suas credenciais. Por favor tente novamente!".',
        });
      }
    };

    if (carregar) {
      setCarregar(false);
      dispatch(resetWhitelabel());
      var parsedURL = new URL(window.location.href);
      var subdomain = parsedURL.hostname.split(".")[0];
      if (!ehProducao() && !ehHomologacao()) {
        subdomain = "homologacao";
      }
      if (
        subdomain !== "localhost" &&
        subdomain !== "app" &&
        subdomain !== "homologacao"
      ) {
        loadPartnerInfo(subdomain);
      } else {
        setDefaultMovipay();
      }
      ChartJS.register(
        CategoryScale,
        LinearScale,
        ArcElement,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
      );
    }
  }, [carregar, dispatch]);

  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
