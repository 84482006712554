import Dialog from "@mui/material/Dialog";
import DownloadIcon from "@mui/icons-material/Download";
import QRCode from "react-qr-code";
import DialogTitle from "@mui/material/DialogTitle";
import LaunchIcon from "@mui/icons-material/Launch";
import { useRef } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { formatMonetario } from "@/services/utils";
import Alert from "@mui/material/Alert";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Grid from "@mui/material/Grid";
import {
  setInfoDialogResultadoLinkPagamento,
  setInfoToaster,
} from "@/store/reducers/geral";
import { useDispatch, useSelector } from "react-redux";
import BotaoText from "@/components/BotaoText";
export default function DialogResultadoLinkPagamento() {
  const exportRef = useRef();
  const dispatch = useDispatch();
  const geral = useSelector((state) => state.geral);
  const paymentLink = geral.infoDialogResultadoLinkPagamento.paymentLink;

  const cancelar = () => {
    dispatch(setInfoDialogResultadoLinkPagamento({ open: false }));
  };

  const downloadQrCode = () => {
    const svg = exportRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <div>
      <Dialog
        open={geral.infoDialogResultadoLinkPagamento.open}
        maxWidth={"sm"}
        onClose={cancelar}
        aria-labelledby="alert-dialog-title"
        className="dialog-transacao"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="close"
            onClick={cancelar}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Alert severity="success">
                <Typography variant="h5">
                  {geral.infoDialogResultadoLinkPagamento.type !== "assinatura"
                    ? "Link de pagamento gerado com sucesso!"
                    : "Assinatura gerada com sucesso!"}
                </Typography>

                <Typography variant="h5">
                  Envie este link para o cliente realizar o pagamento.
                </Typography>

                <Typography sx={{ mt: 1 }}>
                  Valor: <b>{formatMonetario(paymentLink.amount)}</b>
                </Typography>
                {paymentLink.expire_datetime && (
                  <Typography>
                    Expira em: <b>{paymentLink.expire_datetime}</b>
                  </Typography>
                )}
                <Typography>
                  Nome: <b>{paymentLink.title}</b>
                </Typography>
                {paymentLink.description && (
                  <Typography>
                    Descrição: <b>{paymentLink.description}</b>
                  </Typography>
                )}
                <Typography>
                  Formas de pagamento: <b>{paymentLink.expire_datetime}</b>
                </Typography>
                {paymentLink.enable_creditcard && (
                  <Typography>
                    &nbsp;&nbsp;
                    <b>
                      Cartão de crédito - Até {paymentLink.max_installments}x
                    </b>
                  </Typography>
                )}
                {paymentLink.enable_pix && (
                  <Typography>
                    &nbsp;&nbsp;
                    <b>PIX</b>
                  </Typography>
                )}
                {paymentLink.enable_billet && (
                  <Typography>
                    &nbsp;&nbsp;
                    <b>Boleto</b>
                  </Typography>
                )}
              </Alert>
            </Grid>

            {paymentLink.payment_link && (
              <>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <a
                    style={{ textDecoration: "none", color: "black" }}
                    href={paymentLink.payment_link}
                    target="blank"
                  >
                    <div
                      style={{
                        wordWrap: "break-word",
                        cursor: "pointer",
                        padding: "5px",
                        border: "solid",
                        borderColor: "#cfcfcf",
                        borderWidth: "1px",
                      }}
                    >
                      {paymentLink.payment_link}
                    </div>
                  </a>
                </Grid>
                <Grid item xs={12} align="center">
                  <BotaoText
                    component="label"
                    startIcon={<ContentCopyIcon />}
                    onClick={() => {
                      navigator.clipboard.writeText(paymentLink.payment_link);
                      dispatch(
                        setInfoToaster({
                          open: true,
                          message: "Copiado",
                          type: "success",
                        })
                      );
                    }}
                  >
                    COPIAR LINK PARA PAGAMENTO
                  </BotaoText>
                  <BotaoText
                    component="label"
                    startIcon={<LaunchIcon />}
                    onClick={() => {
                      window.open(paymentLink.payment_link, "_blank");
                    }}
                  >
                    ABRIR LINK
                  </BotaoText>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }} align="center">
                  <QRCode
                    ref={exportRef}
                    size={500}
                    style={{
                      height: "auto",
                      maxWidth: "300px",
                      width: "100%",
                    }}
                    value={paymentLink.payment_link}
                    viewBox={`0 0 256 256`}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <BotaoText
                    component="label"
                    startIcon={<DownloadIcon />}
                    onClick={() => {
                      downloadQrCode();
                    }}
                    fullWidth
                  >
                    BAIXAR QR Code
                  </BotaoText>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <BotaoText onClick={cancelar}>Fechar</BotaoText>
        </DialogActions>
      </Dialog>
    </div>
  );
}
