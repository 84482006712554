import DashboardIcon from "@mui/icons-material/Dashboard";
import LinkIcon from "@mui/icons-material/Link";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
const dashboard = {
  id: "group-dashboard",
  title: "",
  type: "group",
  url: "/dashboard",
  children: [
    {
      id: "dashboard",
      title: "INÍCIO",
      type: "item",
      url: "",
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "meus-saldos",
      title: "MEUS SALDOS",
      type: "item",
      url: "meus-saldos",
      icon: AttachMoneyIcon,
      breadcrumbs: false,
    },
    {
      id: "links-pagamento",
      title: "LINKS DE PAGAMENTO",
      type: "item",
      url: "links-pagamento",
      icon: LinkIcon,
      breadcrumbs: false,
    },
    {
      id: "transacoes",
      title: "TRANSAÇÕES",
      type: "item",
      url: "transacoes",
      icon: HistoryIcon,
      breadcrumbs: false,
    },
    {
      title: "ASSINATURAS",
      type: "collapse",
      defaultExpanded: false,
      children: [
        {
          id: "assinaturasClientes",
          title: "ASSINANTES",
          type: "item",
          url: "assinaturas",
          icon: AutorenewIcon,
          breadcrumbs: false,
        },
        {
          id: "planos",
          title: "PLANOS",
          type: "item",
          url: "planos",
          icon: CurrencyExchangeIcon,
          antecipacao: true,
        },
      ],
    },
    {
      id: "clientes",
      title: "CLIENTES",
      type: "item",
      url: "clientes",
      icon: PeopleAltIcon,
      breadcrumbs: false,
    },
    {
      id: "antecipacao",
      title: "ANTECIPAÇÃO",
      type: "item",
      url: "antecipacao",
      icon: CurrencyExchangeIcon,
      antecipacao: true,
    },
    {
      id: "empresas",
      title: "EMPRESAS",
      type: "item",
      url: "empresas",
      icon: BusinessIcon,
      permissao: "visualizar-empresas",
      subconta: true,
    },
    {
      id: "chargebacks",
      title: "CHARGEBACKS",
      type: "item",
      url: "chargebacks",
      icon: ErrorOutlineIcon,
      breadcrumbs: false,
    },
    {
      id: "configuracoes",
      title: "CONFIGURAÇÕES",
      type: "item",
      url: "configuracoes",
      icon: SettingsIcon,
      permissao: "configuracoes",
    },
  ],
};

export default dashboard;
