import {
  Badge,
  Box,
  Container,
  Grid,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Logo from "@/components/Logo";
import Profile from "./Profile";
import { useNavigate } from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import { useEffect, useState } from "react";
import { get } from "@/services/api";
import BotaoText from "@/components/BotaoText";
import { useSelector } from "react-redux";

const HeaderContent = () => {
  const { reloadWarnings } = useSelector((state) => state.warnings);
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [warnings, setWarnings] = useState([]);

  const inicio = () => {
    navigation("/app/area-cliente/inicio");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const addDots = (title) => {
    return title.length > 70 ? title.substr(0, 70) + "..." : title;
  };

  const carregaWarnings = async () => {
    const result = await get("secure/alert/resume?limit=5");
    if (result) {
      setWarnings(result);
    }
  };

  useEffect(() => {
    carregaWarnings();
  }, [reloadWarnings]);

  useEffect(() => {
    const interval = setInterval(() => {
      carregaWarnings();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {!matchesXs && (
        <Box
          sx={{ width: "100%", ml: { xs: 0, md: 1 } }}
          style={{ paddingTop: "6px" }}
        >
          <Logo
            onClick={inicio}
            style={{ cursor: "pointer", maxWidth: "140px" }}
          />
        </Box>
      )}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge
          style={{ marginRight: "-1rem" }}
          badgeContent={warnings.count}
          color="primary"
        >
          <MailIcon sx={{ color: "white" }} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid container spacing={0} sx={{ p: 2 }}>
          <Grid item xs={12}>
            {warnings.count > 0 ? (
              <Container>
                {warnings.alerts.map((alert, index) => (
                  <Typography
                    style={{ cursor: "pointer" }}
                    align="left"
                    sx={{ m: 1 }}
                    key={`alert-resume-${index}`}
                    onClick={() => {
                      window.open(
                        `/app/area-cliente/avisos/aviso/${alert.idAlert}`,
                        `_blank`
                      );
                      handleClose();
                    }}
                  >
                    {addDots(alert.title)}{" "}
                  </Typography>
                ))}
              </Container>
            ) : (
              <Typography align="center" sx={{ m: 1 }}>
                Nenhum novo aviso.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} align="center">
            <BotaoText
              style={{
                width: "100%",
              }}
              onClick={() => navigation("avisos")}
            >
              Ver Todos
            </BotaoText>
          </Grid>
        </Grid>
      </Popover>
      <Profile />
    </>
  );
};

export default HeaderContent;
