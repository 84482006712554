import { useState, useEffect, forwardRef } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  Chip,
  IconButton,
  Divider,
  Slide,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import JsonReleases from "./notas-versao.json";
import BotaoText from "../../components/BotaoText";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const getCorTipo = (tipo) => {
  let corDoTipo = "";

  switch (tipo) {
    case "Correção":
      corDoTipo = "warning";
      break;
    case "Adição":
      corDoTipo = "success";
      break;
    case "Melhoria":
      corDoTipo = "info";
      break;

    default:
      corDoTipo = "default";
      break;
  }

  return corDoTipo;
};

export default function DialogRelease({ open, handleClose }) {
  const [releases, setReleases] = useState([]);

  useEffect(() => {
    let element = [];
    for (let index = 0; index < JsonReleases.length; index++) {
      if (index < 30 && index < JsonReleases.length) {
        element.push(JsonReleases[index]);
      }
    }

    setReleases(element);
  }, [setReleases]);

  // deixa por default o último item expandido
  const expandeAccordion = (release) => {
    for (let index of releases) {
      if (index.data === release.data) {
        return true;
      }
      return false;
    }
  };

  return (
    <Dialog
      keepMounted
      open={open}
      onClose={handleClose}
      scroll="body"
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography
          sx={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}
        >
          Notas de Versão
        </Typography>
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {releases.length > 0 && (
          <>
            {releases.map((release) => {
              return (
                <Accordion defaultExpanded={expandeAccordion(release)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ background: "#0000000d" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {release.data}
                    </Typography>
                  </AccordionSummary>
                  {release.atualizacoes.map((item) => {
                    return (
                      <AccordionDetails>
                        <Stack direction="row" spacing={1}>
                          <Chip
                            size="medium"
                            variant="outlined"
                            label={item.tipo}
                            color={getCorTipo(item.tipo)}
                            sx={{ borderRadius: 3, mb: 1, mt: 2 }}
                          />
                        </Stack>

                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "none",
                            textAlign: "justify",
                            ml: 3,
                            fontSize: 14,
                          }}
                        >
                          {item.descricao}
                        </Typography>
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <BotaoText onClick={handleClose}>FECHAR</BotaoText>
      </DialogActions>
    </Dialog>
  );
}
