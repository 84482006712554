import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import BotaoText from "./BotaoText";

export default function AlertDialog({ infoDialog, setInfoDialog }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setInfoDialog({ open: false }));
  };
  const handleOk = () => {
    if (infoDialog.acaoOk) {
      infoDialog.acaoOk();
      handleClose();
      return;
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={infoDialog.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {infoDialog.titulo || "Aviso"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {infoDialog.descricao}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BotaoText onClick={handleOk} autoFocus>
            Ok
          </BotaoText>
        </DialogActions>
      </Dialog>
    </div>
  );
}
