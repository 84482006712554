import AddIcon from "@mui/icons-material/Add";
import BotaoPrincipal from "./BotaoPrincipal";

export default function BotaoCadastrar(props) {
  return (
    <BotaoPrincipal startIcon={<AddIcon />} {...props}>
      {props.children}
    </BotaoPrincipal>
  );
}
