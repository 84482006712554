import { createTheme } from "@mui/material/styles";
import { getPrimaryColorButton } from "../services/utils";
export default createTheme({
  palette: {
    background: {
      default: "#fafaf3",
    },
    primary: {
      light: getPrimaryColorButton(),
      main: getPrimaryColorButton(),
      dark: getPrimaryColorButton(),
      contrastText: "#ffffff",
    },
  },
});
