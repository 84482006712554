// material-ui
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { getPrimaryColorButton } from "@/services/utils";

const Palette = (mode) => {
  const user = useSelector((state) => state.user);
  return createTheme({
    palette: {
      primary: {
        light: getPrimaryColorButton(user),
        main: getPrimaryColorButton(user),
        dark: getPrimaryColorButton(user),
        contrastText: "#ffffff",
      },
      black: {
        light: "#2E302E",
        main: "#2E302E",
        dark: "#2E302E",
        contrastText: "#2E302E",
      },
    },
  });
};

export default Palette;
