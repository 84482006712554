import { Navigate } from "react-router-dom";
import MinimalLayout from "@/layout/MinimalLayout";

const GeralRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "*",
      element: <Navigate {...{ to: "/app/login" }} />,
    },
    {
      path: "",
      element: <Navigate {...{ to: "/app/login" }} />,
    },
  ],
};

export default GeralRoutes;
