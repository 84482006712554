import { useSelector } from "react-redux";
export default function Logo(props) {
  const user = useSelector((state) => state.user);
  return (
    <div style={{ maxWidth: "110px", display: "flex" }}>
      {user?.logos?.light_background && (
        <img
          src={user.logos.light_background}
          style={{ maxWidth: "100%", marginTop: "auto", marginBottom: "auto" }}
          alt=""
          {...props}
        ></img>
      )}
    </div>
  );
}
