import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useDispatch } from "react-redux";
import BotaoText from "./BotaoText";

export default function DialogFormatoRelatorio({
  infoDialogFormatoRelatorio,
  setInfoDialogFormatoRelatorio,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setInfoDialogFormatoRelatorio({ open: false }));
  };

  const handleCancelar = () => {
    handleClose();
  };
  const handleConfirmar = (tipo) => {
    infoDialogFormatoRelatorio.confirmar(tipo);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={infoDialogFormatoRelatorio.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Deseja gerar o relatório em qual formato?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BotaoText onClick={handleCancelar}>Cancelar</BotaoText>
          <BotaoText
            onClick={() => {
              handleConfirmar("xls");
            }}
            autoFocus
          >
            XLS
          </BotaoText>
          <BotaoText
            onClick={() => {
              handleConfirmar("pdf");
            }}
            autoFocus
          >
            PDF
          </BotaoText>
        </DialogActions>
      </Dialog>
    </div>
  );
}
