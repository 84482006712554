// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import user from "./user";
import geral from "./geral";
import dashboard from "./dashboard";
import warnings from "./warnings";
import transactions from "./transactions";
import client from "./client";
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  user,
  dashboard,
  warnings,
  geral,
  transactions,
  client,
});

export default reducers;
