import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  graficoOptions: null,
};
const transactions = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setGraficoTransactionsOptions(state, action) {
      state.graficoOptions = action.payload;
    },
    resetOptions(state) {
      state.graficoOptions = initialState.graficoOptions;
    },
  },
});

export default transactions.reducer;
export const { setGraficoTransactionsOptions, resetOptions } =
  transactions.actions;
