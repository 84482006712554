import { Outlet } from "react-router-dom";
import Dialog from "@/components/Dialog";
import { useSelector } from "react-redux";
import NavBar from "@/components/navbar";
import Spinner from "@/components/Spinner";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DialogConfirmacao from "@/components/DialogConfirmacao";
import Toaster from "@/components/Toaster";
import theme from "../../pages/theme";
import {
  setInfoDialogConfirmacao,
  setInfoDialog,
} from "@/store/reducers/geral";

const MinimalLayout = () => {
  const geral = useSelector((state) => state.geral);
  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <CssBaseline />
      <Outlet />
      {geral?.infoDialog?.open && (
        <Dialog {...{ infoDialog: geral.infoDialog, setInfoDialog }} />
      )}
      <Spinner {...{ showSpinner: geral.showSpinner }}></Spinner>
      <Toaster {...{ infoToaster: geral.infoToaster }}></Toaster>
      {geral?.infoDialogConfirmacao.open && (
        <DialogConfirmacao
          {...{
            infoDialogConfirmacao: geral.infoDialogConfirmacao,
            setInfoDialogConfirmacao,
          }}
        />
      )}
    </ThemeProvider>
  );
};

export default MinimalLayout;
