import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { getPrimaryColorButton } from "@/services/utils";
export default function BotaoPrincipal(props) {
  const user = useSelector((state) => state.user);
  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: getPrimaryColorButton(user),
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}
