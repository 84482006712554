import { Box } from "@mui/material";
import { resetUser } from "@/store/reducers/user";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import DialogRelease from "@/layout/Release/DialogRelease";
import BotaoText from "@/components/BotaoText";

const Profile = () => {
  const [anchorElOpcoesPerfil, setAnchorElOpcoesPerfil] = useState(null);
  const [open, setOpen] = useState(false);
  const openOpcoesPerfil = Boolean(anchorElOpcoesPerfil);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = async () => {
    dispatch(resetUser());
    navigation("/app/login");
  };
  const handleClickOpcoesPerfil = (event) => {
    setAnchorElOpcoesPerfil(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <BotaoText
          id="button-opcoes-perfil"
          aria-controls={openOpcoesPerfil ? "basic-menu" : undefined}
          aria-haspopup="true"
          style={{ color: "white" }}
          aria-expanded={openOpcoesPerfil ? "true" : undefined}
          onClick={handleClickOpcoesPerfil}
        >
          <PersonIcon style={{ fontSize: "2rem" }}></PersonIcon>
        </BotaoText>

        <Menu
          id="button-opcoes-perfil"
          anchorEl={anchorElOpcoesPerfil}
          open={openOpcoesPerfil}
          onClose={() => {
            setAnchorElOpcoesPerfil(null);
          }}
          MenuListProps={{
            "aria-labelledby": "button-opcoes-perfil",
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorElOpcoesPerfil(null);
              navigation("/app/area-cliente/meus-dados");
            }}
          >
            MEUS DADOS
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorElOpcoesPerfil(null);
              navigation("/app/area-cliente/alterar-senha");
            }}
          >
            ALTERAR SENHA
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorElOpcoesPerfil(null);
              handleLogout();
            }}
          >
            SAIR
          </MenuItem>
        </Menu>
      </Box>

      {open && <DialogRelease handleClose={handleClose} open={open} />}
    </>
  );
};

export default Profile;
