import { lazy } from "react";
import Loadable from "@/components/Loadable";
import MainLayoutParceiro from "@/layout/MainLayoutParceiro";

const Chargebacks = Loadable(
  lazy(() => import("@/pages/area-interna/chargebacks"))
);
const DashboardDefault = Loadable(
  lazy(() => import("@/pages/area-interna/inicio"))
);
const AlterarSenha = Loadable(
  lazy(() => import("@/pages/area-interna/alterar-senha"))
);
const MeusSaldos = Loadable(
  lazy(() => import("@/pages/area-interna/meus-saldos"))
);
const Antecipacao = Loadable(
  lazy(() => import("@/pages/area-interna/antecipacao"))
);
const MeusDados = Loadable(
  lazy(() => import("@/pages/area-interna/meus-dados"))
);
/*
const UsuariosParceiro = Loadable(
  lazy(() =>
    import("@/pages/area-interna/controle-acesso/usuario/usuarios-parceiro")
  )
);*/
const TaxasEmpresa = Loadable(
  lazy(() => import("@/pages/area-interna/empresas/taxas"))
);
const PerfilAcesso = Loadable(
  lazy(() => import("@/pages/area-interna/controle-acesso/perfilAcesso"))
);
const CadastroPerfilAcesso = Loadable(
  lazy(() =>
    import("@/pages/area-interna/controle-acesso/perfilAcesso/cadastro")
  )
);

const CadastroUsuarioControleAcesso = Loadable(
  lazy(() => import("@/pages/area-interna/controle-acesso/usuario"))
);
const Transacoes = Loadable(
  lazy(() => import("@/pages/area-interna/transactions"))
);
const Clientes = Loadable(lazy(() => import("@/pages/area-interna/clientes")));
const Cliente = Loadable(
  lazy(() => import("@/pages/area-interna/clientes/cliente"))
);
const VisualizarTransacoao = Loadable(
  lazy(() => import("@/pages/area-interna/transactions/transacao"))
);

const LinksPagamento = Loadable(
  lazy(() => import("@/pages/area-interna/links-pagamento"))
);
const CriarCobranca = Loadable(
  lazy(() => import("@/pages/area-interna/links-pagamento/criar-cobranca"))
);
const EditarLinkPagamento = Loadable(
  lazy(() =>
    import("@/pages/area-interna/links-pagamento/editar-link-pagamento")
  )
);

const Configuracoes = Loadable(
  lazy(() => import("@/pages/area-interna/configuracoes"))
);

const Assinaturas = Loadable(
  lazy(() => import("@/pages/area-interna/assinaturas"))
);
const CadastroAssinaturas = Loadable(
  lazy(() => import("@/pages/area-interna/assinaturas/cadastro-assinatura"))
);
const VisualizarAssinatura = Loadable(
  lazy(() => import("@/pages/area-interna/assinaturas/visualizar-assinatura"))
);

const Empresas = Loadable(lazy(() => import("@/pages/area-interna/empresas")));
const CadastroEmpresa = Loadable(
  lazy(() => import("@/pages/area-interna/empresas/cadastrar-empresas"))
);
const EditarEmpresas = Loadable(
  lazy(() => import("@/pages/area-interna/empresas/editar-empresas"))
);
const Avisos = Loadable(
  lazy(() => import("@/pages/area-interna/avisos/ListagemAvisos"))
);
const DetalhesAviso = Loadable(
  lazy(() => import("@/pages/area-interna/avisos/DetalhesAviso"))
);
const ConfiguracoesAvisos = Loadable(
  lazy(() => import("@/pages/area-interna/avisos/configuracoes-avisos"))
);

const UsuariosEmpresa = Loadable(
  lazy(() => import("@/pages/area-interna/empresas/usuarios-empresa"))
);
const EditarUsuario = Loadable(
  lazy(() =>
    import("@/pages/area-interna/empresas//usuarios-empresa/editar-usuarios")
  )
);

const CadastroPlano = Loadable(
  lazy(() => import("@/pages/area-interna/assinaturas/planos/cadastro-plano"))
);
const Planos = Loadable(
  lazy(() => import("@/pages/area-interna/assinaturas/planos"))
);
const VisualizarPlano = Loadable(
  lazy(() => import("@/pages/area-interna/assinaturas/planos/visualizar-plano"))
);

const MainRoutes = {
  path: "app/area-cliente",
  element: <MainLayoutParceiro />,
  children: [
    {
      path: "",
      element: <DashboardDefault />,
    },
    {
      path: "usuario/company/:idCompany/:id_user",
      element: <EditarUsuario />,
    },
    {
      path: "usuarios-empresa/:idCompany",
      element: <UsuariosEmpresa />,
    },
    {
      path: "taxas-empresa/:idCompany",
      element: <TaxasEmpresa />,
    },
    {
      path: "empresa/:idCompany",
      element: <EditarEmpresas />,
    },
    {
      path: "empresas/cadastro",
      element: <CadastroEmpresa />,
    },
    {
      path: "empresas",
      element: <Empresas />,
    },
    {
      path: "clientes",
      element: <Clientes />,
    },
    {
      path: "cliente/:id",
      element: <Cliente />,
    },

    {
      path: "controle-acesso/perfis-acesso",
      element: <PerfilAcesso />,
    },
    {
      path: "controle-acesso/perfis-acesso/cadastro-acesso",
      element: <CadastroPerfilAcesso />,
    },

    {
      path: "controle-acesso/company/:id_company/:id_user",
      element: <CadastroUsuarioControleAcesso />,
    },

    {
      path: "controle-acesso/company/:id_company",
      element: <CadastroUsuarioControleAcesso />,
    },

    {
      path: "controle-acesso/perfis-acesso/:idAccessProfile",
      element: <CadastroPerfilAcesso />,
    },
    {
      path: "alterar-senha",
      element: <AlterarSenha />,
    },
    {
      path: "meus-dados",
      element: <MeusDados />,
    },
    {
      path: "antecipacao",
      element: <Antecipacao />,
    },
    {
      path: "transacoes",
      element: <Transacoes />,
    },
    {
      path: "visualizar-transacao/:idTransaction",
      element: <VisualizarTransacoao />,
    },
    {
      path: "meus-saldos",
      element: <MeusSaldos />,
    },
    {
      path: "links-pagamento",
      element: <LinksPagamento />,
    },
    {
      path: "criar-cobranca/etapa/:etapa",
      element: <CriarCobranca />,
    },
    {
      path: "editar-link-pagamento/etapa/:etapa/:idLink",
      element: <EditarLinkPagamento />,
    },
    {
      path: "chargebacks",
      element: <Chargebacks />,
    },
    {
      path: "assinaturas",
      element: <Assinaturas />,
    },
    {
      path: "cadastro-assinatura/:etapa",
      element: <CadastroAssinaturas />,
    },
    {
      path: "visualizar-assinatura/:idAssinatura",
      element: <VisualizarAssinatura />,
    },
    {
      path: "planos",
      element: <Planos />,
    },
    {
      path: "planos/cadastro",
      element: <CadastroPlano />,
    },
    {
      path: "visualizar-plano/:idPlano",
      element: <VisualizarPlano />,
    },
    {
      path: "avisos",
      element: <Avisos />,
    },
    {
      path: "avisos/aviso/:id",
      element: <DetalhesAviso />,
    },
    {
      path: "avisos/configuracoes",
      element: <ConfiguracoesAvisos />,
    },
    {
      path: "configuracoes",
      element: <Configuracoes />,
    },

    {
      path: "inicio",
      element: <DashboardDefault />,
    },
    {
      path: "*",
      element: <DashboardDefault />,
    },
  ],
};

export default MainRoutes;
