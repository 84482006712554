import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSpinner: false,
  infoDialog: {
    open: false,
    titulo: "",
    descricao: "",
  },
  infoToaster: {},
  infoDialogConfirmacao: {},
  infoDialogFormatoRelatorio: {},
  infoDialogResultadoLinkPagamento: { open: false },
  infoDialogVisualizarTransacao: { open: false },
  infoDialogExclusaoPlano: { open: false },
};

const geral = createSlice({
  name: "geral",
  initialState,
  reducers: {
    activeSpinner(state) {
      state.showSpinner = true;
    },

    cancelSpinner(state) {
      state.showSpinner = false;
    },
    setInfoDialog(state, action) {
      state.infoDialog = action.payload;
    },
    setInfoToaster(state, action) {
      state.infoToaster = action.payload;
    },
    setInfoDialogConfirmacao(state, action) {
      state.infoDialogConfirmacao = action.payload;
    },
    setInfoDialogFormatoRelatorio(state, action) {
      state.infoDialogFormatoRelatorio = action.payload;
    },

    setInfoDialogLinkTransacao(state, action) {
      state.infoDialogLinkTransacao = action.payload;
    },
    setInfoLinkTransacao(state, action) {
      state.infoLinkTransacao = action.payload;
    },

    setInfoDialogResultadoLinkPagamento(state, action) {
      state.infoDialogResultadoLinkPagamento = action.payload;
    },

    setInfoDialogExclusaoPlano(state, action) {
      state.infoDialogExclusaoPlano = action.payload;
    },

    resetGeral(state) {
      state.infoDialogResultadoLinkPagamento =
        initialState.infoDialogResultadoLinkPagamento;
      state.infoDialogLinkTransacao = initialState.infoDialogLinkTransacao;
      state.showSpinner = initialState.showSpinner;
      state.infoToaster = initialState.infoToaster;
      state.infoDialogConfirmacao = initialState.infoDialogConfirmacao;
      state.infoDialog = initialState.infoDialog;
      state.infoDialogFormatoRelatorio =
        initialState.infoDialogFormatoRelatorio;
      state.infoDialogExclusaoPlano =
        initialState.infoDialogExclusaoPlano;
    },
  },
});

export default geral.reducer;

export const {
  activeSpinner,
  setInfoDialogConfirmacao,
  resetGeral,
  cancelSpinner,
  setInfoDialogResultadoLinkPagamento,
  setInfoDialogLinkTransacao,
  setInfoLinkTransacao,
  setInfoDialogFormatoRelatorio,
  setInfoDialog,
  setInfoToaster,
  setInfoDialogExclusaoPlano,
} = geral.actions;
