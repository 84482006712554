import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  usuarioLogado: {},
  keepQuitaLogo: false,
  defaultColors: {},
  logos: {},
};
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsuarioLogado(state, action) {
      state.usuarioLogado = action.payload;
    },
    resetUser(state) {
      state = initialState;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
    setDefaultColors(state, action) {
      state.defaultColors = action.payload;
    },
    setLogos(state, action) {
      state.logos = action.payload;
    },
    setKeepQuitaLogo(state, action) {
      state.keepQuitaLogo = action.payload;
    },
    resetWhitelabel(state) {
      state.defaultColors = initialState.defaultColors;
      state.logos = initialState.logos;
      state.keepQuitaLogo = initialState.keepQuitaLogo;
    },
  },
});

export default user.reducer;

export const {
  setUsuarioLogado,
  resetUser,
  setLogos,
  setDefaultColors,
  setPermissions,
  setKeepQuitaLogo,
  resetWhitelabel,
} = user.actions;
