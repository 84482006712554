import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

export const AccordionNavGroup = styled((props) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  return (
    <MuiAccordion
      disableGutters
      elevation={0}
      square
      {...props}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${14}px` : 1.5,
        py: !drawerOpen && 1.25,
        ...(drawerOpen && {
          "&:hover": {
            bgcolor: "primary.lighter",
          },
        }),
        ...(!drawerOpen && {
          "&:hover": {
            bgcolor: "transparent",
          },
          "&.Mui-selected": {
            "&:hover": {
              bgcolor: "transparent",
            },
            bgcolor: "transparent",
          },
        }),
      }}
    />
  );
})(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummaryNavGroup = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetailsNavGroup = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: 0,
  })
);
