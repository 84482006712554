import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useDispatch } from "react-redux";
import BotaoText from "./BotaoText";
import {
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useState } from "react";

export default function DialogExclusaoPlano({
  infoDialogExclusaoPlano,
  setInfoDialogExclusaoPlano,
}) {
  const dispatch = useDispatch();
  const [desativarAssinaturas, setDesativarAssinaturas] = useState(false);
  const [avisarClientes, setAvisarClientes] = useState(false);

  const handleClose = () => {
    dispatch(setInfoDialogExclusaoPlano({ open: false }));
  };

  const handleNao = () => {
    if (infoDialogExclusaoPlano.acaoNao) {
      infoDialogExclusaoPlano.acaoNao();
    }
    handleClose();
  };
  const handleSim = () => {
    if (infoDialogExclusaoPlano.acaoSim) {
      infoDialogExclusaoPlano.acaoSim(desativarAssinaturas, avisarClientes);
    }
    handleClose();
  };

  return (
    <Dialog
      open={infoDialogExclusaoPlano.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {infoDialogExclusaoPlano.titulo || "Aviso"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {infoDialogExclusaoPlano.descricao}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    label="Desativar assinatura dos clientes?"
                    control={
                      <Switch
                        name="desativarAssinaturas"
                        checked={desativarAssinaturas}
                        onChange={(event, checked) => {
                          setDesativarAssinaturas(checked);
                        }}
                      />
                    }
                  />
                  {desativarAssinaturas && (
                    <FormControlLabel
                      label="Avisar assinantes?"
                      control={
                        <Switch
                          name="avisarClientes"
                          checked={avisarClientes}
                          onChange={(event, checked) => {
                            setAvisarClientes(checked);
                          }}
                        />
                      }
                    />
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BotaoText onClick={handleNao}>Não</BotaoText>
        <BotaoText onClick={handleSim} autoFocus>
          Sim
        </BotaoText>
      </DialogActions>
    </Dialog>
  );
}
