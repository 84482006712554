import { useTheme } from "@mui/material/styles";
import { getTopBarColor } from "@/services/utils";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import DrawerHeaderStyled from "./DrawerHeaderStyled";

const DrawerHeader = ({ open }) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();

  return (
    <DrawerHeaderStyled
      style={{ backgroundColor: getTopBarColor(user) }}
      theme={theme}
      open={open}></DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
