import { useState } from "react";
import { ehHomologacao } from "../../services/api";
import {
  getLightColorButton,
  getTopBarColor,
  retornaUrlSite,
} from "../../services/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Logo from "../Logo";
import MenuItem from "@mui/material/MenuItem";
import "./styles.scss";

const ResponsiveAppBar = (params: any) => {
  const navigation = useNavigate();
  const user = useSelector((state: any) => state.user);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const abrirSite = () => {
    navigation("/app/login");
  };
  return (
    <AppBar style={{ backgroundColor: getTopBarColor(user) }} position="static">
      <Container
        style={{ backgroundColor: getTopBarColor(user) }}
        maxWidth="xl">
        <Toolbar
          style={{ backgroundColor: getTopBarColor(user) }}
          disableGutters>
          <Typography
            noWrap
            style={{ color: "white", cursor: "pointer" }}
            onClick={abrirSite}
            sx={{
              mt: 1,
              mr: 2,
              display: { xs: "none", md: "flex" },
              textDecoration: "none",
            }}>
            <Logo {...{ maxWidth: "180px" }} />
            {ehHomologacao() ? " - HOMOLOGAÇÃO" : ""}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{ color: "white" }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              <MenuItem
                onClick={() => {
                  window.location.href = retornaUrlSite();
                }}>
                <Typography
                  textAlign="center"
                  style={{
                    color: getLightColorButton(user),
                  }}>
                  VOLTAR AO SITE
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/app/login"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}>
            <img
              src={user?.logos?.light_background}
              className="logo-header"
              alt=""></img>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}>
            <Button
              className="header-item"
              style={{ color: getLightColorButton(user) }}
              onClick={() => {
                window.location.href = retornaUrlSite();
              }}
              sx={{ my: 2, mr: 2, display: "block" }}>
              Voltar ao site
            </Button>
            {params.exibirEntrar && (
              <Button
                className="header-item header-item-entrar"
                style={{ color: getLightColorButton(user) }}
                onClick={() => {
                  navigation("/parceiro/login");
                }}
                sx={{ my: 2, color: "white", display: "block" }}>
                Entrar
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
